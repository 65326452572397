let solutionsCard = document.querySelectorAll('.solutions-card');
let breakpoint = window.innerWidth > 992;
if (solutionsCard) {
  solutionsCard.forEach((card) => {
    let content = $(card).find('.solutions-card-content');
    let buttonWrapper = content.find('.flex');
    $(card).hover(
      function () {
        breakpoint ? content.stop(true, true).slideDown() : buttonWrapper.stop(true, true).slideDown();
      },
      function () {
        breakpoint ? content.stop(true, true).slideUp() : buttonWrapper.stop(true, true).slideUp();
      }
    );
  });
}
