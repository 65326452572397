import { CountUp } from 'countup.js';

/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 * **/
window.countUp = function countUp(element = 'count-up', idSuffix = 'count-up') {
    const counts = Array.from(document.getElementsByClassName(element));
    if (counts) {
        let idNumber = 1;

        counts.forEach((count) => {

            const defaultOptions = {
                separator: '',
                decimal: '',
                enableScrollSpy: false,
                scrollSpyRunOnce: true,
            };

            let value = parseFloat(count.textContent);

            const id = `${idSuffix}-${idNumber}`;
            let separatorsArr = [':', '.', ',', '/'];
            let text = count.textContent.trim();
            let separatorPosition, separator;

            separatorsArr.forEach(char =>{
                if(text.includes(char)){
                    separatorPosition = text.indexOf(char) < 0 ? 0 : text.length - 1 - text.indexOf(char);
                    separator = text.charAt(text.indexOf(char))
                    defaultOptions.decimal = separator;
                    value = parseFloat(text.replace(separator, '.'));
                    defaultOptions.decimalPlaces = separatorPosition
                }
            })


            let optionsFromDataAttr = {...count.dataset};


            for (const key in optionsFromDataAttr) {
                if (optionsFromDataAttr[key] === '') {
                    optionsFromDataAttr[key] = true;
                }
            }

            count.id = id;
            let counter = new CountUp(
                id,
                value,
                Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
            );
            idNumber++;

            let options = {
                root: document,
                rootMargin: "0px",
                threshold: 0,
            };
            let cb = (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        counter.start()
                    }
                });
            };
            let observer = new IntersectionObserver(cb, options);
            observer.observe(count);

        });

    }
}
countUp();

