import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let blogsSwiperWr = document.querySelectorAll('.blogs-swiper-wr');
blogsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
     let breakpoints = {
       550: {
             spaceBetween: 20,
             slidesPerView: 2,
           },
           768: {
             spaceBetween: 20,
             slidesPerView: 3,
           },
           1200:{
             slidesPerView: 3,
             spaceBetween: 30,
           }
         };
     if(el.classList.contains('blogs-swiper-wr-article')){
       console.log('cont')
       breakpoints = {
         550: {
           spaceBetween: 20,
           slidesPerView: 2,
         },
         768: {
           spaceBetween: 20,
           slidesPerView: 3,
         },
         1200:{
           slidesPerView: 4,
           spaceBetween: 30,
         }
       };
     }

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: breakpoints,
       autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
