import './utils/vh.utils.js';
import './utils/tilt.utils.js';
import './utils/animate-on-view.utils';

import './components/global.component.js';
import './components/popups.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/progress.component.js';
import './components/header.component.js';
import './components/solutions.component.js';
import './components/tilt.component.js';
import './components/aos.component.js';
import './components/simple-select.component.js';


/*swipers*/
import './components/roles-swiper.component.js';
import './components/client-ts-swiper.component.js';
import './components/industry-swiper.component.js';
import './components/news-swiper.component.js';
import './components/offices-swiper.component.js';
import './components/swiper-count.component.js';
import './components/swiper-blogs.component.js';
import './components/swiper-benefits.component.js';
import './components/swiper-sol.component.js';
import './components/swiper-projects.component.js';
import './components/swiper-contact-offices.component.js';
import './components/swiper-why.component.js';
import './components/swiper-products.component.js';
import './components/swiper-choose.component.js';
/*end of swipers*/

import './libs/countUp.lib.js';
