import Swiper, { Autoplay, EffectFade} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let whySwiperWr = document.querySelectorAll('.why-section-wrapper');
whySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');

    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
     let tabs = el.querySelectorAll('.why-section-tab-item');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      allowTouchMove: false,
      speed: 800,
    });
    swiperObserver(swiper);

    tabs.forEach((el,idx)=>{
      el.addEventListener('click', ()=>{
        el.classList.toggle('active');
        el.querySelector('.toggle-btn').classList.toggle('active');
        tabs.forEach((el, index)=>{
          if(index !== idx){
            el.classList.remove('active');
            el.querySelector('.toggle-btn').classList.remove('active');
          }
        })
        swiper.slideTo(idx);
      })
    })
  }
});


