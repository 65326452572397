window.initTilt = function initTilt(selectorArr) {
  let tiltArr = document.querySelectorAll(selectorArr);
  if (tiltArr) {
    tiltArr.forEach((el) => {
      if (!el.classList.contains('tilt-initialized')) {
        el.classList.add('tilt-initialized');
        let tilt = VanillaTilt.init(el, {
          max: 5,
          speed: 400,
          perspective: 500,
        });
      }
    });
  }
};
initTilt('.tilt-item');
