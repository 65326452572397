import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let countSwiperWr = document.querySelectorAll('.count-swiper-wr');
countSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
     let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      slidesPerView: 'auto',
      speed: 800,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
        effect: 'creative',
        creativeEffect: {
            limitProgress: slidesCount,
            prev: {
                translate: ['-90%', 0, 0],
            },
            next: {
                translate: ['90%', 0, 10],
            },
        },

        breakpoints:{
            1024: {
                slidesPerView: 1,
                creativeEffect: {
                    limitProgress: slidesCount,
                    prev: {
                        translate: ['-90%', 0, 0],
                    },
                    next: {
                        translate: ['90%', 0, 10],
                    },
                },
            },
            1200: {
                slidesPerView: 1,
                creativeEffect: {
                    limitProgress: slidesCount,
                    prev: {
                        translate: ['-80%', 0, 0],
                    },
                    next: {
                        translate: ['80%', 0, 10],
                    },
                },
            },
        },

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
