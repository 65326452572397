import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';

let officeSwiperWr = document.querySelectorAll('.office-swiper-wr');
officeSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');

      if(swiperEl != null){
          let nextEl = el.querySelector('.swiper-button-next');
          let prevEl = el.querySelector('.swiper-button-prev');
          let slidesCount = el.querySelectorAll('.swiper-slide').length;
          let wrapper = swiperEl.querySelector('.swiper-wrapper');
          swiperOnResize('(max-width: 992px)', swiperEl,
              {
                  modules: [Autoplay, Navigation],
                  slidesPerView: 'auto',
                  spaceBetween: 32,
                  speed: 800,
                  autoplay: {
                      delay: 4000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                  },
                  navigation: {
                      prevEl: prevEl,
                      nextEl: nextEl,
                  },

                  breakpoints:{
                      651:{
                          slidesPerView: 2,
                      }
                  }
              },
          );
      }
  }
});