let teammatePopup = document.querySelector('.teammate-popup');

window.openTeammatePopup = function openTeammatePopup(element){
    let card = element;
    if(event.target.tagName !== 'A'){
        $(teammatePopup).fadeIn({
            start: function(){
                let thumbnail = $(card).find('.teammate-card-thumbnail-wrapper').html();
                let info = $(card).find('.teammate-popup-card').html();
                $(teammatePopup).find('.teammate-popup-card').html(info);
                $(teammatePopup).find('.teammate-popup-card-thumbnail').html(thumbnail);
            },
            complete:function(){
                scrollLock.disablePageScroll(this)
                scrollLock.addScrollableSelector(this)
            }
        })
    }
}
window.closeTeammatePopup = function closeTeammatePopup(){
    $(teammatePopup).fadeOut();
    scrollLock.enablePageScroll()
}

window.openExtFormPopup = function openExtFormPopup(popupId){
    event.preventDefault();
    $(popupId).fadeIn({
        start: function(){
            $(this).css('display', 'flex');
            scrollLock.disablePageScroll(this)
            scrollLock.addScrollableSelector(this)
        }
    })
}
window.closeExtFormPopup = function closeExtFormPopup(popupId){
    $(popupId).fadeOut(function(){scrollLock.enablePageScroll()})
}