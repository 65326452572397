import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';
import {swiperObserver} from "~src/js/utils/swiper.utils.js";

let industrySwiperWr = document.querySelectorAll('.industry-swiper-wr');
industrySwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let cards = el.querySelectorAll('.industry-card');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    let autolpay = {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    };

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      slidesPerView: 1,
      speed: 800,
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
       autoplay: autolpay,

      effect: 'creative',
      creativeEffect: {
        limitProgress: slidesCount,
        prev: {
          translate: ['-70%', 0, 0],
        },
        next: {
          translate: ['70%', 0, 10],
        },
      },
      breakpoints: {


        1024: {
          slidesPerView: 1,
          allowTouchMove: slidesCount > 4,
          autoplay: slidesCount > 4 ? autolpay : false,
          creativeEffect: {
            limitProgress: slidesCount,
            prev: {
              translate: ['-50%', 0, 0],
            },
            next: {
              translate: ['52%', 0, 10],
            },
          },
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      on:{
        afterInit: function(){
          cards.forEach((card, idx)=>{
            let currentIdx = idx;
            let activeIndex = window.innerWidth < 1024 ? 0 : 1;
            if(idx === activeIndex){
              card.classList.add('active');
            }
            card.addEventListener('mouseenter', function(){
              cards.forEach((card, idx)=>{
                idx === currentIdx ? card.classList.add('active') : card.classList.remove('active')
              })
            })
          })
        },
      }
    });
    swiperObserver(swiper);
  }
});



