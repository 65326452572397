import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.js';

let newsSwiperWr = document.querySelectorAll('.news-swiper-wr');
newsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 992px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 'auto',
      spaceBetween: 8,
      speed: 800,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
});

window.newsCardsGridInit = function newsCardsGridInit() {
  const newsResultsWrapper = document.querySelector('.news-results-wrapper');
  if (newsResultsWrapper) {
    const cards = newsResultsWrapper.querySelectorAll('.news-card');

    for (let i = 0; i < cards.length; i++) {
      cards[i].classList.remove('news-card-50');
    }

    if (cards && cards.length === 5) {
      cards[3].classList.add('news-card-50');
    }

    if (cards && cards.length === 2) {
      cards[1].classList.add('news-card-50');
    }
  }
};
newsCardsGridInit();
