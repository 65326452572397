window.initSimpleSelect = function initSimpleSelect(selector) {
  let simpleSelect = document.querySelectorAll(selector);

  window.removeSimpleFilter = function removeSimpleFilter(targer) {
    let target = targer;
    let select = document.getElementById(target.dataset.selectId);
    let checkboxes = select.querySelectorAll('input');
    let targetInput = checkboxes[target.dataset.inputIdx];
    targetInput.checked = false;
    let checkedInputs = select.querySelectorAll('input[type=checkbox]:checked');
    let selectItem = select.querySelector('.simple-select-item');
    let placeholderItem = selectItem.querySelector('span');
    let currentPlaceHolder = select.dataset.simpleSelectPlaceholoder
      ? select.dataset.simpleSelectPlaceholoder
      : '';
    let newPlaceHolder = '';

    if (checkedInputs.length > 1) {
      newPlaceHolder = `${checkedInputs.length} items selected`;
    } else if (checkedInputs.length === 1) {
      newPlaceHolder = checkedInputs[0].parentElement.querySelector(
        '.simple-select-list-item'
      ).innerText;
    } else {
      newPlaceHolder = currentPlaceHolder;
      select.classList.add('active-placeholder');
    }
    placeholderItem.innerText = newPlaceHolder.trim();

    target.closest('.simple-select-filter-bth').remove();
  };

  simpleSelect.forEach((select, selectIdx) => {
    let selectItem = select.querySelector('.simple-select-item');
    let dropDown = select.querySelector('.simple-select-dropdown');
    let checkboxes = select.querySelectorAll('input');
    let placeholderItem = selectItem.querySelector('span');
    let isMultiply = select.getAttribute('data-multiple') !== null;
    let dataFilters = document.querySelector(select.dataset.targetFilters);
    let currentPlaceHolder = select.dataset.simpleSelectPlaceholoder
      ? select.dataset.simpleSelectPlaceholoder
      : '';

    select.id = `simple-select-${selectIdx}`;
    placeholderItem.innerText = currentPlaceHolder;

    selectItem.addEventListener('click', function () {
      select.classList.toggle('active');
    });

    selectItem.classList.add('active-placeholder');

    checkboxes.forEach((input, idx) => {
      input.addEventListener('change', function () {
        let currentElementPlaceholder = this.parentElement.querySelector(
          '.simple-select-list-item'
        ).innerText;
        let newPlaceholder = currentElementPlaceholder;
        let checkedInputs = select.querySelectorAll(
          'input[type=checkbox]:checked, input[type=radio]:checked'
        );
        selectItem.classList.remove('active-placeholder');
        if (checkedInputs.length > 1) {
          newPlaceholder = `${checkedInputs.length} items selected`;
        } else if (checkedInputs.length === 1) {
          newPlaceholder = checkedInputs[0].parentElement.querySelector(
            '.simple-select-list-item'
          ).innerText;
        } else {
          newPlaceholder = currentPlaceHolder;
          selectItem.classList.add('active-placeholder');
        }
        placeholderItem.innerText = newPlaceholder;

        if (!isMultiply) {
          select.classList.remove('active');
        }

        if (this.checked) {
          if (dataFilters) {
            let button = ` <div class="simple-select-filter-bth">
                  <i class="icon-close" data-select-id="${select.id}" data-input-idx="${idx}" onclick="removeSimpleFilter(this)"></i>
                  <span>${currentElementPlaceholder}</span>
                </div>`;
            dataFilters.innerHTML += button;
          }
        } else {
          let currentFilterButtons = dataFilters.querySelectorAll(
            `[data-select-id="${select.id}"]`
          );
          currentFilterButtons.forEach((button) => {
            if (button.dataset.inputIdx == idx) {
              button.closest('.simple-select-filter-bth').remove();
            }
          });
        }
      });
    });

    select.addEventListener('mouseenter', () => {
      select.classList.add('is-hover');
    });
    select.addEventListener('mouseleave', () => {
      select.classList.remove('is-hover');
    });

    document.addEventListener('click', function (e) {
      if (e.target.closest('.simple-select') === null) {
        select.classList.remove('active');
      }
      simpleSelect.forEach((select) => {
        if (!select.classList.contains('is-hover')) {
          select.classList.remove('active');
        }
      });
    });
  });
};
initSimpleSelect('.simple-select');
