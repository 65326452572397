window.setBoxMbCounter = function setBoxMbCounter(scrollContainer, listenTagsName) {
    let tags = scrollContainer.querySelectorAll(listenTagsName);
    if (tags) {
        let mbCount = 0;
        let parentHeight = scrollContainer.clientHeight;
        let targetHeight = 0;

        tags.forEach((tag) => {
            let mb = parseFloat(window.getComputedStyle(tag)['marginBottom']);
            targetHeight += tag.offsetHeight + mb;
            if (parentHeight > targetHeight) {
                mbCount++;
            }
        });

        scrollContainer.style.setProperty('--mb-count', mbCount);
    }
}