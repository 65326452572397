import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let clientTsSwiperWr = document.querySelectorAll('.client-ts-swiper-wr');
clientTsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      loop: slidesCount > 1,
      speed: 800,
      threshold: 10,
      /*autoHeight: true, */
      effect: 'creative',
      creativeEffect: {
        prev: {
          translate: [0, 0, -400],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },

      /*autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },*/
    });
    /*swiperObserver(swiper);*/
  }
});
