const headerComponent = document.getElementsByClassName('header')[0];
const body = document.getElementsByTagName('body')[0];

// Header scroll
const scrollContainer = () => document.documentElement || document.body;

document.addEventListener('scroll', () => {
  if (scrollContainer().scrollTop > 200) {
    headerComponent.classList.add('scrolled');
  } else if (scrollContainer().scrollTop == 0) {
    headerComponent.classList.remove('scrolled');
  }
});

// menu handlers

// check mobile menu show/hide condition
const mobileMenuStartPoint = parseInt(
  getComputedStyle(document.documentElement).getPropertyValue(
    '--mobile-menu-start-point'
  )
);
let isMobileMenuEnable =
  window.outerWidth <= mobileMenuStartPoint ||
  document.querySelector('.header-mobile');

const dropdownToggle = document.querySelectorAll('.dropdown-toggle');

dropdownToggle.forEach((toggle) => {
  toggle.addEventListener('click', function (e) {
    if (isMobileMenuEnable) {
      const menuItem = this.closest('.menu-item.dropdown');
      const dropdownMenu = this.nextElementSibling;

      // close all opened sub menu
      const activeDropdowns = document.querySelectorAll(
        '.menu-item.dropdown.active .dropdown-menu'
      );
      activeDropdowns.forEach((menu) => {
        menu.style.display = 'none';
        menu.closest('.dropdown').classList.remove('active');
      });

      // open current submenu
      menuItem.classList.toggle('active');
      if (menuItem.classList.contains('active')) {
        e.preventDefault();
        dropdownMenu.style.display = 'block';
      }
    }
  });
});

// toggle menu handler
function menuToggleFunc() {
  const menuToggle = document.querySelector('.menu-toggle');
  const navbarNav = document.querySelector('.navbar-nav');
  const headerCloseWrapper = document.querySelector('.header-close-wrapper');
  let scrollDuration = window.scrollY > 0 ? 300 : 0;

  if (!body.classList.contains('show-menu')) {
    $('html').animate(
      {
        scrollTop: 0,
      },
      scrollDuration,
      function () {
        menuToggle.classList.toggle('active');
        body.classList.toggle('show-menu');
        navbarNav.classList.toggle('active');
        if (headerCloseWrapper) headerCloseWrapper.classList.toggle('active');
        headerComponent.classList.toggle('menu-open');

        // LockScroll when burger open and enable when closed and enable scroll on menu
        if (scrollLock.getScrollState()) {
          scrollLock.disablePageScroll(
            document.querySelector('.navbar-nav .menu')
          );
          scrollLock.addScrollableSelector('.simplebar-content-wrapper');
        } else {
          scrollLock.enablePageScroll();
        }
      }
    );
  } else {
    menuToggle.classList.toggle('active');
    body.classList.toggle('show-menu');
    navbarNav.classList.toggle('active');
    headerCloseWrapper.classList.toggle('active');
    headerComponent.classList.toggle('menu-open');

    // LockScroll when burger open and enable when closed and enable scroll on menu
    if (scrollLock.getScrollState()) {
      scrollLock.disablePageScroll(document.querySelector('.navbar-nav .menu'));
      scrollLock.addScrollableSelector('.simplebar-content-wrapper');
    } else {
      scrollLock.enablePageScroll();
    }
  }

  /* */
}

// menu update function
function updateMenu() {
  isMobileMenuEnable =
    window.outerWidth <= mobileMenuStartPoint ||
    document.querySelector('.mobile-header');

  if (!isMobileMenuEnable) {
    const dropdownMenus = document.querySelectorAll('.dropdown-menu');
    dropdownMenus.forEach((menu) => {
      menu.style.display = '';
    });

    const headerCloseWrapper = document.querySelector('.header-close-wrapper');
    headerCloseWrapper.classList.remove('active');

    const activeMenuItems = document.querySelectorAll('.menu-item.active');
    activeMenuItems.forEach((item) => {
      item.classList.remove('active');
    });

    const navbarNav = document.querySelector('.navbar-nav');
    navbarNav.classList.remove('active');

    const menuToggle = document.querySelector('.menu-toggle');
    menuToggle.classList.remove('active');
    body.classList.remove('show-menu');

    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}

window.addEventListener('resize', updateMenu);
window.addEventListener('orientationchange', updateMenu);

// end of toggle menu handler

const menuToggle = document.querySelector('.menu-toggle');
const headerCloseWrapper = document.querySelector('.header-close-wrapper');

if (menuToggle) menuToggle.addEventListener('click', menuToggleFunc);
if (headerCloseWrapper)
  headerCloseWrapper.addEventListener('click', menuToggleFunc);

// Hide and show Header when scroll up
function headerSticky() {
  if (window.scrollY > 0) {
    headerComponent.classList.add('scrolled');
  } else {
    headerComponent.classList.remove('scrolled');
  }
}

headerSticky();

window.addEventListener('scroll', headerSticky);

const menuItems = document.querySelectorAll('.menu-item');

function setTransitionDelay(selector, startDelay = 0, delayStep = 50) {
  let elArr =
    typeof selector === 'object'
      ? selector
      : document.querySelectorAll(`${selector}`);
  if (elArr) {
    if (elArr.length > 1) {
      elArr.forEach((link, idx) => {
        link.style.transitionDelay = idx * delayStep + startDelay + 'ms';
      });
    } else if (elArr.length === 1) {
      elArr[0].style.transitionDelay = delayStep + startDelay + 'ms';
    } else {
      elArr.style.transitionDelay = delayStep + startDelay + 'ms';
    }
  }
}
setTransitionDelay(menuItems, 200, 50);

if (document.querySelector('.menu-dropdown')) {
  const menuDropdownsArr = document.querySelectorAll('.menu-dropdown');
  menuDropdownsArr.forEach((menuDropdown) => {
    const dropdownList = menuDropdown.querySelector('.menu-dropdown__list');

    menuDropdown.addEventListener('click', (e) => {
      if (window.innerWidth <= 1280) {
        if (!menuDropdown.classList.contains('active')) {
          e.preventDefault();
          menuDropdown.classList.add('active');
          dropdownList.classList.add('active');
        } else {
          menuDropdown.classList.remove('active');
          dropdownList.classList.remove('active');
        }
      }
    });
  });
}
